body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.subtle-btn {
  text-decoration: none;
}

.subtle-btn:hover {
  font-weight: 500;
  cursor: pointer;
}

.boxed-border {
  border: #ccc solid 1px;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 20px;
}

:root {
  --primary: #a80404;
  --secondary: #222;
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 80%;
  height: 60vh;
}

.pin {
  display: block;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.pin-icon {
  font-size: 4rem;
  height: 40px;
  color: #a80404;
  color: var(--primary);
}

.pin-text {
  font-size: 1.3em;
  color: #222;
  color: var(--secondary);
  font-weight: bold;
  text-align: center;
}

.rounded-btn {
  padding: 10px 20px;
  background-color: #3377ab;
  color: white;
  border-radius: 30px;
  box-shadow: none;
  border-style: solid;
  border-width: 0px;
}

.signal {
}

.signal-icon {
  font-size: 1.5rem;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.signal-green {
  color: green;
}
.signal-red {
  color: red;
}
.fixed {
  position: relative;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.signal-yellow {
  color: rgb(0, 17, 174);
}

.signal-text {
  font-size: 1em;
  color: #222;
  color: var(--secondary);
  font-weight: bold;
  position: absolute;
  -webkit-transform: translate(-50%, 15px);
          transform: translate(-50%, 15px);
}

