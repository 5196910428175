:root {
  --primary: #a80404;
  --secondary: #222;
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 80%;
  height: 60vh;
}

.pin {
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
}

.pin-icon {
  font-size: 4rem;
  height: 40px;
  color: var(--primary);
}

.pin-text {
  font-size: 1.3em;
  color: var(--secondary);
  font-weight: bold;
  text-align: center;
}

.rounded-btn {
  padding: 10px 20px;
  background-color: #3377ab;
  color: white;
  border-radius: 30px;
  box-shadow: none;
  border-style: solid;
  border-width: 0px;
}

.signal {
}

.signal-icon {
  font-size: 1.5rem;
  position: absolute;
  transform: translate(-50%, -50%);
}

.signal-green {
  color: green;
}
.signal-red {
  color: red;
}
.fixed {
  position: relative;
  transform: translate(0, 0);
}
.signal-yellow {
  color: rgb(0, 17, 174);
}

.signal-text {
  font-size: 1em;
  color: var(--secondary);
  font-weight: bold;
  position: absolute;
  transform: translate(-50%, 15px);
}
